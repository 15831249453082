import { createRouter, createWebHashHistory ,createWebHistory} from 'vue-router'
import HomeView from '../views/index.vue'

const routes = [
  {
    path:'/',
    redirect:'/index',
  },
  {
    path: '/index',
    name: 'index',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
