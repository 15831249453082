<template>
	<div class="container">
		<div v-if="isWeixin" id="openBrower">
			<img src="@/assets/a.png" class="browerImg">
			<!-- <img src="https://www.lingzhikang.com/web/profile/static/ios_wx.png" class="browerImg"
				v-else-if="model == 'ios'"> -->
		</div>
		<div class="wrap">
			<div class="logo">
				<img src="https://img.waimaidashi.com/logo10.png" alt="">
				<!-- <div style="font-size: 32px;font-weight: 600;margin-top: 20px;">外卖大师</div> -->
			</div>
			<div class="downLoad">
				<div class="btn" @click="goLink(1)">
					<img style="width: 22px; height: 25px; margin-right: 10px"
						src="https://img.waimaidashi.com/android10.png" alt="" />
					<span>Android下载</span>
				</div>
				<!-- <div class="btn">
					<img style="width: 19px; height: 22px; margin-right: 10px"
						src="https://img.waimaidashi.com/ios10.png" alt="" />
					<span>iPhone下载</span>
				</div> -->
				<div class="btn" @click="goLink(2)">
					<span>我已安装</span>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		onMounted,
		reactive,
		ref
	} from "vue";
	import {
		showToast,
		showSuccessToast,
		showDialog,
		showFailToast,
		Toast
	} from "vant";
	import API from "@/config/api";


	import {
		useRoute
	} from "vue-router";

	export default {
		setup() {
			let isWeixin = ref(false)
			let model = ref('')
			onMounted(() => {
				if (is_weixn()) {
					isWeixin.value = true
					model.value = is_Android()
					return
				}
				// try {
				// 	download('wmdsapp://aaa', '外卖大师')
				// } catch {
				// 	console.log(2)
				// 	setTimeout(() => {
				// 		if (is_Android() == 'android') {
				// 			// 安卓手机
				// 			getVersion()
				// 		} else if (is_Android() == 'ios') {
				// 			//苹果手机
				// 			window.location.href = "https://apps.apple.com/cn/app";
				// 		}
				// 	}, 2000);
				// }
			})


			// 判断是微信浏览器吗
			function is_weixn() {
				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					return true;
				} else {
					return false;
				}
			}

			function is_Android() {
				// 判断手机机型
				var u = navigator.userAgent;
				if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
					//安卓手机
					return 'android'
				} else if (u.indexOf("iPhone") > -1) {
					//苹果手机
					return 'ios'
				}
			}

			function goLink(e) {
				if (e == 1) {
					getVersion()
				} else if (e == 2) {
					download('wmdsapp://aaa', '外卖大师')
				}
			}

			function download(href, name) {
				// 创建隐藏的可下载链接
				var eleLink = document.createElement('a');
				eleLink.setAttribute("href", href);
				eleLink.setAttribute("download", name);
				eleLink.style.display = 'none';
				// 触发点击
				document.body.appendChild(eleLink);
				eleLink.click();
				// 然后移除
				document.body.removeChild(eleLink);
			}

			function getVersion() {
				API.getVersion().then((res) => {
					if (res.code == 200) {
						let dataA = res.rows.find(a => a.id == 1)
						download(dataA.url, '外卖大师')
					} else {
						showToast(res.msg)
					}

				});
			}

			return {
				isWeixin,
				model,
				goLink,
				getVersion
			};
		},
	};
</script>
<style scoped lang="scss">
	.wrap {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-direction: column;
		width: 100vw;
		height: 100vh;
		background: url(https://img.waimaidashi.com/downLoadBg.png) no-repeat;
		background-size: cover;
		overflow: hidden;
	}

	.downLoad {
		position: absolute;
		bottom: 30px;
	}

	.btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 360px;
		height: 46px;
		background: #6C23A8;
		margin-bottom: 26px;
		border-radius: 45px;
		font-size: 16px;
		font-family: PingFang Semibold;
		color: #ffffff;
	}

	a {
		color: #fff;
		text-decoration: none;
	}

	.logo {
		position: absolute;
		top: 30%;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.logo img {
		width: 100px;
		height: 100px;
	}

	#openBrower {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, .6);
		z-index: 99;
		display: block;
	}

	.browerImg {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		display: block;
	}
</style>