<template>

  <router-view/>
</template>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: PingFangSC-Semibold, PingFang SC;
}
html,
body,
#app{
  width: 100%;
  height: 100%;
}
</style>
