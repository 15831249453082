import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import { CountDown  ,Checkbox,Overlay,Popup } from 'vant';
import 'vant/lib/index.css';


createApp(App).use(router).use(CountDown).use(Checkbox).use(Overlay).use(Popup).mount('#app')


